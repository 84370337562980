import React, { useState } from 'react'

export default function SignIn() {

    return (
        <main className='flex justify-center items-center h-screen'>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="grid max-w-screen gap-8 row-gap-6 sm:mx-auto lg:grid-cols-2">
                    <a
                        href="/webapp/schadsignin"
                        aria-label="View item"
                        className="relative block p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl"
                    >
                        
                        <div className="relative flex items-center justify-between p-5 bg-white rounded-sm">
                            <div className="pr-4">
                                <h6 className="mb-2 font-semibold leading-5">
                                    Sign In As A School Admin
                                </h6>
                            </div>
                            <div className="flex items-center justify-center">
                                <svg
                                    className="w-3 text-gray-700 transition-colors duration-300 group-hover:text-deep-purple-accent-400"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                >
                                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                                </svg>
                            </div>
                        </div>
                    </a>
                    <a
                        href="/webapp/usersignin"
                        aria-label="View item"
                        className="relative block p-px overflow-hidden transition duration-300 transform border rounded shadow-sm hover:scale-105 group hover:shadow-xl"
                    >
                        
                        <div className="relative flex items-center justify-between p-5 bg-white rounded-sm">
                            <div className="pr-4">
                                <h6 className="mb-2 font-semibold leading-5">
                                    Sign In As A User [Teacher or Student]
                                </h6>
                            </div>
                            <div className="flex items-center justify-center">
                                <svg
                                    className="w-3 text-gray-700 transition-colors duration-300 group-hover:text-deep-purple-accent-400"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                >
                                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </main>
    )
}
