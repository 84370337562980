import React, { useState } from 'react'
import { Header } from '../../../../Components'
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../../../../apiService/apiService'

export default function SignIn() {
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
    
        try {
            const response = await loginUser({ userId, password });
            const token = response.data.token;
            const role = response.data.role;
    
            // Store token in local storage
            localStorage.setItem('token', token);
    
            // Redirect to specific dashboard based on role
            if (userId.startsWith('STDN')) {
                navigate('/webapp/students'); 
            } else if (userId.startsWith('TCHR')) {
                navigate('/webapp/teacher/assignmentset'); 
            } else {
                setError('Invalid role or user ID');
            }
        } catch (err) {
            console.log(err.response);
            setError('Login failed. Please check your credentials.');
        }
    };


    return (
        <main>
            <Header />
            <section className="bg-white">
                <div className="flex flex-col items-center justify-center px-6 py-32 md:py-40 mx-auto md:h-screen lg:py-0">
                    <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-gray-900 dark:border-gray-900">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white capitalize pb-3">
                                Sign In With Your ID and Password
                            </h1>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                                <div className="relative">
                                    <label className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">Your ID</label>
                                    <input placeholder="your id" type="text" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={userId} onChange={(e) => setUserId(e.target.value)} required />
                                </div>
                                <div>
                                    <label for="password" className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">Your Password</label>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </div>

                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label for="terms" className="font-light text-gray-500 dark:text-gray-300">Remember Me</label>
                                    </div>
                                </div>
                                <button type="submit" className='mt-3'>
                                    <div className="w-full text-gray-900 bg-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign In</div>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
