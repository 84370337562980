import React from 'react'
import './Style.css';

export default function PageLoader() {
  return (
    <main className="page-loader">
      <div className="spinner"></div>
    </main>
  )
}
