import React from 'react'
import { FaSquareXTwitter, FaLinkedin, FaGithub } from "react-icons/fa6";
import { AboutBG } from '../../../../../Assets';
// import { Kavod } from '../../../../Assets';

export default function Body() {
    return (
        <main>
            {/* 2024 Team */}
            <section className="px-4 py-16 mx-auto max-w-[1443px] lg:px-0 lg:py-32">
                <div className="grid gap-10 lg:grid-cols-2">
                    <div className='w-full'>
                        <img src={AboutBG} className="object-contain w-full h-56 rounded sm:h-96" />
                    </div>

                    <div className="max-w-[1443px] w-full flex flex-col lg:justify-start justify-center lg:mt-10">
                        <h5 className="mb-4 lg:text-4xl text-xl font-extrabold leading-none inline-flex gap-3 lg:justify-start justify-center items-center uppercase lg:text-start text-center">
                            About Us
                        </h5>
                        <hr className='mb-5 border-gray-300' />
                        <p className="mb-6 text-gray-900 lg:text-start text-center">
                            EduSync is a comprehensive platform designed to revolutionize the way schools manage assignments and academic activities. By offering an integrated solution for assignment submission, school calendar management, and class scheduling, EduSync simplifies the communication between students, teachers, and administrators.
                            <br /><br />
                            The platform allows students to upload assignments, track deadlines, and receive feedback, while teachers can easily grade submissions and monitor student progress. School administrators benefit from a centralized dashboard that enables them to manage events, oversee assignment activities, and ensure smooth academic operations.
                        </p>
                    </div>

                </div>
            </section>
            {/* Core Team */}
            <div className="px-4 py-16 mx-auto max-w-[1443px] md:px-24 lg:px-0 lg:py-20">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        EduSync Team Members
                    </h2>
                </div>
                <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
                    {/* Kavod */}
                    <div>
                        <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-64 xl:h-80"
                                // src={Kavod}
                                alt="Person"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                                <p className="mb-1 text-lg font-bold text-gray-100">
                                    Ubongabasi Joseph
                                </p>
                                <p className="mb-4 text-xs text-gray-100">Team Member</p>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        href="https://github.com/GDSC-Estam"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaGithub />
                                    </a>
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaSquareXTwitter />
                                    </a>
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Audrey */}
                    <div>
                        <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-64 xl:h-80"
                                // src={Kavod}
                                alt="Person"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                                <p className="mb-1 text-lg font-bold text-gray-100">
                                    ?????
                                </p>
                                <p className="mb-4 text-xs text-gray-100">Team Member</p>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaSquareXTwitter />
                                    </a>
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Korede */}
                    <div>
                        <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-64 xl:h-80"
                                // src={Kavod}
                                alt="Person"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                                <p className="mb-1 text-lg font-bold text-gray-100">
                                    ???
                                </p>
                                <p className="mb-4 text-xs text-gray-100">Team Member</p>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaSquareXTwitter />
                                    </a>
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Elijah */}
                    <div>
                        <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                            <img
                                className="object-cover w-full h-56 md:h-64 xl:h-80"
                                // src={Kavod}
                                alt="Person"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                                <p className="mb-1 text-lg font-bold text-gray-100">
                                    ?????
                                </p>
                                <p className="mb-4 text-xs text-gray-100">Team Member</p>
                                <div className="flex items-center justify-center space-x-3">
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaSquareXTwitter />
                                    </a>
                                    <a
                                        href="/"
                                        className="text-blueColor transition-colors duration-300 text-3xl"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
