import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { About, Contact, Home } from './Layouts/Pages/Landing';
import { SAdSignIn, SignIn, SignUp, StSignIn, TSignIn, UserSignIn } from './Layouts/Pages/Landing/GetStarted';
import { PageLoader } from './Layouts/Constants';
import { Logout, SchoolAdmin, StudentAdmin, StudentDashboard, TeacherDashboard } from './Layouts/Pages/Dashboard'
import { AssignmentsSetStart, AssignStudentAdmin, TeacherNotification, TStudents } from './Layouts/Pages/Dashboard/Teacher/Layout';
import { AuthProvider } from './Layouts/Context/AuthContext';
import { ACourses, AStudents, ATeachers, ATimetable } from './Layouts/Pages/Dashboard/Admin/Layout';

function WebAppRoutes() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the path includes '/webapp', indicating the routes inside the web app
    if (location.pathname.startsWith('/webapp')) {
      setLoading(true); // Start loading
      // Simulate loading process and hide after a timeout (e.g., 2 seconds)
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust this timing as necessary

      // Cleanup on route change or unmounting
      return () => clearTimeout(timer);
    } else {
      setLoading(false); // Disable loader for non-webapp routes
    }
  }, [location]);

  return (
    <div className="webapp">
      {loading && <PageLoader />}
      <AuthProvider>
        <Routes>
          {/* Sign Up */}
          <Route path='/webapp/signup' element={<SignUp />} />

          {/* Sign In */}
          <Route path='/webapp/signin' element={<SignIn />} />
          <Route path='/webapp/schadsignin' element={<SAdSignIn />} />
          <Route path='/webapp/usersignin' element={<UserSignIn />} />

          {/* Admin Dashboards */}
          <Route path='/webapp/schooladmin' element={<SchoolAdmin />} />
          <Route path='/webapp/studentadmin' element={<StudentAdmin />} />
          <Route path='/webapp/schteachers' element={<ATeachers />} />
          <Route path='/webapp/schstudentlist' element={<AStudents />} />
          <Route path='/webapp/schooltimetable' element={<ATimetable />} />
          <Route path='/webapp/schcourses' element={<ACourses />} />

          {/* Student Dashboard */}
          <Route path='/webapp/students' element={<StudentDashboard />} />

          {/* Teacher Dashboard */}
          {/* <Route path='/webapp/teacher' element={<TeacherDashboard />} /> */}
          <Route path="/webapp/teacher/assignmentset" element={<AssignmentsSetStart />} />
          <Route path="/webapp/teacher/notifications" element={<TeacherNotification />} />
          <Route path="/webapp/teacher/assign" element={<AssignStudentAdmin />} />
          <Route path="/webapp/teacher/students" element={<TStudents />} />
        {/* <Route path="/webapp/teacher/courses" element={<AssignStudentAdmin />} /> */}
          <Route path="/webapp/teacher/logout" element={<Logout />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {/* Home */}
          <Route path='/' element={<Home />} />

          {/* About */}
          <Route path='/about' element={<About />} />

          {/* Contact */}
          <Route path='/contact' element={<Contact />} />

          {/* WebApp Routes */}
          <Route path="/*" element={<WebAppRoutes />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
