import axios from 'axios';

// Set base URL for your API
const API_URL = 'https://edusync-api.onrender.com/api';

// Auth APIs
export const signup = (data) => axios.post(`${API_URL}/auth/signup`, data);
export const signin = (credentials) => axios.post(`${API_URL}/auth/signin`, credentials);

// School Admin APIs
export const createUser = (data) => axios.post(`${API_URL}/schoolAdmin/createUser`, data);
export const loginUser = (credentials) => axios.post(`${API_URL}/schoolAdmin/login`, credentials);
export const getAllTeachers = (data) => axios.get(`${API_URL}/schoolAdmin/teachers`, data);
export const getAllStudentsAndAdmins = (data) => axios.get(`${API_URL}/schoolAdmin/students`, data);

// Student APIs
export const uploadAssignment = (data) => axios.post(`${API_URL}/student/uploadAssignment`, data);
export const getStudentNotifications = (data) => axios.get(`${API_URL}/student/notifications/:studentId`, data);

// Student Admin APIs
export const scheduleClass = (data) => axios.post(`${API_URL}/studentAdmin/scheduleClass`, data);
export const notifyTeacher = (data) => axios.post(`${API_URL}/studentAdmin/notifyTeacher`, data);

// Teacher APIs
export const createAssignmentAndNotify = (data) => axios.post(`${API_URL}/teacher/createAssignment`, data);
export const markAssignment = (data) => axios.post(`${API_URL}/teacher/markAssignment`, data);
export const assignStudentAdmin = (data) => axios.post(`${API_URL}/teacher/assignStudentAdmin`, data);

// API responses and errors
export const handleApiCall = async (apiCall, params) => {
  try {
    const response = await apiCall(params);
    return response.data;
  } catch (error) {
    console.error('API call error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

