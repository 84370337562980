import React, { useState, useContext } from 'react';
import { Logo } from '../../../../../Assets';
import { MdOutlineAssignmentTurnedIn, MdOutlineAssignmentInd, MdNotificationAdd } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context/AuthContext';

export default function ATimetable() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext); 

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null); 

    // Redirect to the sign-in page and prevent going back
    navigate('/webapp/schadsignin', { replace: true });
  };

  return (
    <div className="flex h-screen bg-white">
      {/* Sidebar overlay */}
      {sidebarOpen && (
        <div
          onClick={() => setSidebarOpen(false)}
          className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition-transform transform bg-white ${sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
          } lg:translate-x-0 lg:static lg:inset-0`}
      >
        <div className="flex items-center px-4 mt-8">
          <div className="flex items-center">
            <img src={Logo} alt='Logo' className='w-40' />
          </div>
        </div>

        <nav className="mt-10">
          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schteachers">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentTurnedIn />
            </div>
            <span className="mx-3">Teachers</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schstudentlist">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentInd />
            </div>
            <span className="mx-3">Students</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-white bg-black" href="/webapp/schooltimetable">
            <div className='text-[20px] font-bold'>
              <PiStudentBold />
            </div>
            <span className="mx-3">School Time Table</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schcourses">
            <div className='text-[20px] font-bold'>
              <FaChalkboardTeacher />
            </div>
            <span className="mx-3">Courses</span>
          </a>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-black">
          <div className="flex items-center">
            <button
              onClick={() => setSidebarOpen(true)}
              className="text-gray-500 focus:outline-none lg:hidden"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>

            <div className="relative mx-4 lg:mx-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>

              <input
                className="w-32 pl-10 pr-4 rounded-md form-input sm:w-64 focus:border-black"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="flex items-center">
            {/* Notification */}
            <div className="relative">
              <button onClick={() => setNotificationOpen(!notificationOpen)} className="flex text-[32px] mx-4 text-gray-600 focus:outline-none">
                <MdNotificationAdd />
              </button>

              {notificationOpen && (
                <div className="absolute right-0 w-64 mt-2 py-2 bg-white border rounded-lg shadow-xl">
                  <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                    Notification 1
                  </a>
                  <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                    Notification 2
                  </a>
                </div>
              )}
            </div>

            {/* Profile dropdown */}
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="relative block text-[32px] overflow-hidden rounded-full shadow focus:outline-none"
              >
                <IoPersonCircleSharp />
              </button>

              {dropdownOpen && (
                <>
                  <div
                    onClick={() => setDropdownOpen(false)}
                    className="fixed inset-0 z-10 w-full h-full"
                  ></div>
                  <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Profile
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Products
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogout();
                      }}
                      className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white"
                    >
                      Logout
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-3xl font-medium text-gray-700">School Timetable</h3>

            {/* Add your content here */}
          </div>
        </main>
      </div>
    </div>
  );
};