import React, { useState, useContext, useEffect } from 'react';
import { Logo } from '../../../../../Assets';
import { MdOutlineAssignmentTurnedIn, MdOutlineAssignmentInd, MdNotificationAdd } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context/AuthContext';
import { createUser, getAllTeachers } from '../../../../../apiService/apiService';
import { MdOutlinePassword } from 'react-icons/md';

export default function ATeachers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('teacher'); // Assuming "teacher" role for this form
  // const [error, setError] = useState('');
  const { setUser } = useContext(AuthContext);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/webapp/schadsignin', { replace: true });
  };

  // Function to generate a random password
  const generatePassword = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
    let generatedPassword = '';
    for (let i = 0; i < 10; i++) {
      generatedPassword += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setPassword(generatedPassword);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') setName(value);
    else if (name === 'email') setEmail(value);
    else if (name === 'password') setPassword(value);
    else if (name === 'role') setRole(value);
  };

  const handleCreateUser = async () => {

    // Check for empty fields
    if (!name || !email || !password || !role) {
      setError("All fields are required.");
      return;
    }

    const userData = {
      name,
      email,
      password,
      role,
      userId,
    };

    try {
      const response = await createUser(userData);
      console.log('User Created', response.data);
    } catch (err) {
      setError(err.response?.data?.message || "User Account Creation Fail!. Please try again.");
    }
  };


  useEffect(() => {
    fetchTeachers();
  }, []);

  const fetchTeachers = async () => {
    try {
      const response = await getAllTeachers();
      console.log(response.data);
      setTeachers(response.data);
    } catch (error) {
      console.error("Failed to fetch teachers", error);
    }
  };


  const handleCopy = (type, value) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        alert(`${type} copied to clipboard!`);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  }  
  

  return (
    <div className="flex h-screen bg-white">
      {/* Sidebar overlay */}
      {sidebarOpen && (
        <div
          onClick={() => setSidebarOpen(false)}
          className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition-transform transform bg-white ${sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
          } lg:translate-x-0 lg:static lg:inset-0`}
      >
        <div className="flex items-center px-4 mt-8">
          <div className="flex items-center">
            <img src={Logo} alt='Logo' className='w-40' />
          </div>
        </div>

        <nav className="mt-10">
          <a className="flex items-center px-6 py-2 mt-4 text-white bg-black" href="/webapp/schteachers">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentTurnedIn />
            </div>
            <span className="mx-3">Teachers</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schstudentlist">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentInd />
            </div>
            <span className="mx-3">Students</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schooltimetable">
            <div className='text-[20px] font-bold'>
              <PiStudentBold />
            </div>
            <span className="mx-3">School Time Table</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/schcourses">
            <div className='text-[20px] font-bold'>
              <FaChalkboardTeacher />
            </div>
            <span className="mx-3">Courses</span>
          </a>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-black">
          <div className="flex items-center">
            <button
              onClick={() => setSidebarOpen(true)}
              className="text-gray-500 focus:outline-none lg:hidden"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>

            <div className="relative mx-4 lg:mx-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>

              <input
                className="w-32 pl-10 pr-4 rounded-md form-input sm:w-64 focus:border-black"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="flex items-center">
            {/* Notification */}
            <div className="relative">
              <button onClick={() => setNotificationOpen(!notificationOpen)} className="flex text-[32px] mx-4 text-gray-600 focus:outline-none">
                <MdNotificationAdd />
              </button>

              {notificationOpen && (
                <div className="absolute right-0 w-64 mt-2 py-2 bg-white border rounded-lg shadow-xl">
                  <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                    Notification 1
                  </a>
                  <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                    Notification 2
                  </a>
                </div>
              )}
            </div>

            {/* Profile dropdown */}
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="relative block text-[32px] overflow-hidden rounded-full shadow focus:outline-none"
              >
                <IoPersonCircleSharp />
              </button>

              {dropdownOpen && (
                <>
                  <div
                    onClick={() => setDropdownOpen(false)}
                    className="fixed inset-0 z-10 w-full h-full"
                  ></div>
                  <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Profile
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Products
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogout();
                      }}
                      className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white"
                    >
                      Logout
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto py-8">
            {/* Create Teacher Account */}
            <div class="flex flex-col justify-start w-[400px]">
              <div class="relative py-3">
                <div class="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl lg:p-10">
                  <div class="">
                    <div class="flex items-center space-x-5">
                      <div class="h-14 w-14 bg-black rounded-full flex flex-shrink-0 justify-center items-center text-white text-2xl font-mono">T</div>
                      <div class="block pl-2 font-semibold text-xl text-gray-700">
                        <h2 class="leading-relaxed">Create Teacher Login</h2>
                      </div>
                    </div>
                    <form class="divide-y divide-gray-200" onSubmit={handleCreateUser}>
                      <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div class="flex flex-col">
                          <label class="leading-loose">Teacher Name</label>
                          <input type="text" name='name' class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Teacher Name" required value={name} onChange={handleChange} />
                        </div>
                        <div class="flex flex-col">
                          <label class="leading-loose">Teacher Email</label>
                          <input type="email" name='email' class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Teacher Email" required value={email} onChange={handleChange} />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-black">Role</label>
                          <select
                            name="role"
                            value={role}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-black focus:border-black"
                          >
                            <option value="" >Select your role</option>
                            <option value="Teacher">Teacher</option>
                            <option value="Student">Student</option>
                          </select>
                        </div>

                        <div class="flex flex-col">
                          <label class="leading-loose">Teacher Password</label>
                          <div className='flex justify-center items-center gap-2'>
                            <input type="text" name='password' class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-[300px] sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Teacher Pasword" required value={password} />
                            <button type="button" onClick={generatePassword} className='text-white bg-black rounded-md p-2.5'><MdOutlinePassword /></button>
                          </div>
                        </div>
                      </div>
                      <button type='submit' class="bg-black flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Create Teacher Account</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* List of teachers */}
            <div class="flex flex-col justify-start lg:py-12 max-w-[1443px]">
              <div class="flex flex-wrap mb-5">
                <div class="w-full mb-6  mx-auto">
                  <div class="relative flex-[1_auto] flex flex-col break-words bg-clip-border rounded-[.95rem] bg-white">
                    <div class="relative flex flex-col break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                      {/* table header */}
                      <div class="px-9 pt-5 flex justify-between items-stretch flex-wrap min-h-[70px] pb-0 bg-transparent">
                        <h3 class="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                          <span class="mr-3 font-semibold text-dark">List of teachers</span>
                        </h3>
                      </div>


                      <div class="flex-auto block py-8 pt-6 px-9">
                        <div class="overflow-x-auto">
                          <table class="w-full my-0 align-middle text-dark border-neutral-200">
                            <thead class="align-bottom w-full">
                              <tr class="font-semibold text-[0.95rem] text-secondary-dark">
                                <th className="px-3 py-2 text-start">Name</th>
                                <th className="px-3 py-2 text-start">Email</th>
                                <th className="px-3 py-2 text-end">Teacher ID</th>
                                <th className="px-3 py-2 text-end">Password</th>
                                <th className="px-3 py-2 text-right">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {teachers.length > 0 ? (
                                teachers.map((teachers) => (
                                  <tr key={teachers.userId} className="border-b border-dashed last:border-b-0">
                                    <td className="p-3 pl-0">
                                      <div className="flex items-center">
                                        <div className="flex flex-col justify-start">
                                          <p className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary">
                                            {teachers.name}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-3 pl-0">
                                      <div className="flex items-center">
                                        <div className="flex flex-col justify-start">
                                          <p className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary">
                                            {teachers.email}
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-3 pr-0 text-end">
                                      <span className="font-semibold text-light-inverse text-md/normal">
                                        {teachers.userId}
                                      </span>
                                      <span className="ml-2 font-semibold text-light-inverse text-md/normal cursor-pointer" onClick={() => handleCopy('User ID', teachers.userId)} >
                                        copy
                                      </span>
                                    </td>
                                    <td className="p-3 pr-0 text-end">
                                      <span className="font-semibold text-light-inverse text-md/normal">
                                        {teachers.password}
                                      </span>
                                      <span className="ml-2 font-semibold text-light-inverse text-md/normal cursor-pointer" onClick={() => handleCopy('Password', teachers.password)}>
                                        copy
                                      </span>
                                    </td>
                                    <td className="p-3 pr-0 text-end">
                                      <span className="font-semibold text-light-inverse text-md/normal cursor-pointer">
                                        Edit
                                      </span>
                                    </td>
                                    <td className="p-3 pr-0 text-end">
                                      <span className="font-semibold text-light-inverse text-md/normal cursor-pointer">
                                        Delete
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="p-3 text-center text-gray-500">
                                    No teachers available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </main>
      </div>
    </div>
  );
};