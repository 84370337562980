import React from 'react'

export default function Body() {
    return (
        <main className="mx-auto flex max-w-[1443px] items-center justify-between py-6 px-2 md:px-5 lg:px-10">
            <div className="px-4 py-16 mx-auto max-w-[1443px] md:px-24 lg:px-8 lg:py-0">
                <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        Why Choose EduSync?
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                        Streamlining Academic Workflows for Students, Teachers, and Administrators.
                    </p>
                </div>
                <div className="grid gap-8 row-gap-5 mb-8 md:row-gap-8 lg:grid-cols-4 sm:grid-cols-2">
                    <div className="duration-300 transform bg-white border-l-4 border-black hover:-translate-y-2">
                        <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                            <h6 className="mb-2 font-semibold leading-5">Assignment Submission System</h6>
                            <p className="text-sm text-black">
                                Easily submit assignments, track deadlines, and receive instant feedback.
                            </p>
                        </div>
                    </div>
                    <div className="duration-300 transform bg-white border-l-4 border-black hover:-translate-y-2">
                        <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                            <h6 className="mb-2 font-semibold leading-5">School Calendar Integration</h6>
                            <p className="text-sm text-gray-900">
                                Stay updated with real-time events and class schedules.
                            </p>
                        </div>
                    </div>
                    <div className="duration-300 transform bg-white border-l-4 border-black hover:-translate-y-2">
                        <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                            <h6 className="mb-2 font-semibold leading-5">
                                Teacher's Dashboard
                            </h6>
                            <p className="text-sm text-gray-900">
                                Empower teachers with tools for grading and managing student progress.
                            </p>
                        </div>
                    </div>
                    <div className="duration-300 transform bg-white border-l-4 border-black hover:-translate-y-2">
                        <div className="h-full p-5 border border-l-0 rounded-r shadow-sm">
                            <h6 className="mb-2 font-semibold leading-5">Mobile-Friendly Design</h6>
                            <p className="text-sm text-gray-900">
                                Access EduSync from any device, anytime, anywhere.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
