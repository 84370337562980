import React, { useState } from 'react';
import { signup } from '../../../../apiService/apiService';
import { Header } from '../../../Components'
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page refresh on form submission

        // Check for empty fields
        if (!name || !email || !password || !confirmPassword || !role) {
            setError("All fields are required.");
            return;
        }

        // Validate passwords match
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        const userData = {
            name,
            email,
            password,
            role, 
        };

        try {
            const response = await signup(userData);
            console.log('Signup successful:', response.data);
            navigate('/webapp/signin'); 
        } catch (err) {
            setError(err.response?.data?.message || "Signup failed. Please try again.");
        }
    };


    return (
        <main>
            <Header />
            <section className="bg-white">
                <div className="flex flex-col items-center justify-center px-6 py-32 md:py-40 mx-auto md:h-screen lg:py-0">
                    <div className="w-full rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 bg-gray-900 dark:border-gray-900">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white capitalize">
                                Create your institution account
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                {error && <p className="text-red-500">{error}</p>}
                                <div>
                                    <div className="relative">
                                        <label className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">School Name</label>
                                        <input placeholder="Your School Name" type="text" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={name} onChange={(e) => setName(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="relative">
                                    <label className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">School Email</label>
                                    <input placeholder="yourschool@email.com" type="email" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div>
                                    <label htmlFor="role" className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">Role</label>
                                    <select
                                        id="role"
                                        className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 text-base block bg-gray-900 border-gray-300 rounded-md"
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select your role</option>
                                        <option value="SchoolAdmin">School Admin</option>
                                        <option value="Teacher">Teacher</option>
                                        <option value="Student">Student</option>
                                    </select>
                                </div>
                                <div>
                                    <label for="password" className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">Password</label>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                </div>
                                <div>
                                    <label for="confirm-password" className="bg-gray-900 pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-white absolute">Confirm Password</label>
                                    <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="border placeholder-gray-400 text-white focus:outline-none focus:border-white w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-gray-900 border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                </div>
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label for="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
                                    </div>
                                </div>
                                <button type="submit" className="w-full text-gray-900 bg-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create an account</button>
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Already have an account? <a href="/webapp/signin" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
