import React, { useState, useContext } from 'react';
import { Logo } from '../../../../../Assets';
import { MdOutlineAssignmentTurnedIn, MdOutlineAssignmentInd, MdNotificationAdd } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'
import { createAssignmentAndNotify } from '../../../../../apiService/apiService'
import { AuthContext } from '../../../../Context/AuthContext';

export default function Assignments({ onAssignmentCreated }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [questions, setQuestions] = useState([{ questionText: '', answerOptions: ['', '', '', ''], correctAnswer: '' }]);
  const { setUser } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/webapp/schadsignin', { replace: true });
  };

  const addQuestion = () => {
    setQuestions([...questions, { questionText: '', answerOptions: ['', '', '', ''], correctAnswer: '' }]);
  };

  const handleCreateAssignment = async () => {
    if (!title || !startDate || !endDate || questions.length === 0) {
        alert('Please fill in all fields.');
        return;
    }

    try {
        const assignmentData = { title, startDate, endDate, questions };
        console.log('Assignment Data:', assignmentData);
        const response = await createAssignmentAndNotify(assignmentData);
        console.log('API Response:', response);
        onAssignmentCreated();
        alert('Assignment created and students have been notified.');
    } catch (error) {
        console.error('Error creating assignment', error);
        alert('Failed to create assignment. Please try again.');
    }
};


  return (
    <div className="flex h-screen bg-white">
      {/* Sidebar overlay */}
      {sidebarOpen && (
        <div
          onClick={() => setSidebarOpen(false)}
          className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition-transform transform bg-white ${sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
          } lg:translate-x-0 lg:static lg:inset-0`}
      >
        <div className="flex items-center px-4 mt-8">
          <div className="flex items-center">
            <img src={Logo} alt='Logo' className='w-40' />
          </div>
        </div>

        <nav className="mt-10">
          <a className="flex items-center px-6 py-2 mt-4 text-white bg-black " href="/webapp/teacher/assignmentset">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentTurnedIn />
            </div>
            <span className="mx-3">AssignmentsSet</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/teacher/assign">
            <div className='text-[20px] font-bold'>
              <MdOutlineAssignmentInd />
            </div>
            <span className="mx-3">Assign Student Admin</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/teacher/students">
            <div className='text-[20px] font-bold'>
              <PiStudentBold />
            </div>
            <span className="mx-3">Students</span>
          </a>

          <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="#">
            <div className='text-[20px] font-bold'>
              <FaChalkboardTeacher />
            </div>
            <span className="mx-3">Courses</span>
          </a>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-black">
          <div className="flex items-center">
            <button
              onClick={() => setSidebarOpen(true)}
              className="text-gray-500 focus:outline-none lg:hidden"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>

            <div className="relative mx-4 lg:mx-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>

              <input
                className="w-32 pl-10 pr-4 rounded-md form-input sm:w-64 focus:border-black"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="flex items-center">
            {/* Notification */}
            <div className="relative">
              <button onClick={() => setNotificationOpen(!notificationOpen)} className="flex mx-4 text-[32px] text-gray-600 focus:outline-none">
                <MdNotificationAdd />
              </button>

              {notificationOpen && (
                <div className="absolute right-0 w-64 mt-2 py-2 bg-white border rounded-lg shadow-xl">
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-black hover:text-white">
                    Notification 1
                  </a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-black hover:text-white">
                    Notification 2
                  </a>
                </div>
              )}
            </div>

            {/* Profile dropdown */}
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="relative block text-[32px] overflow-hidden rounded-full shadow focus:outline-none"
              >
                <IoPersonCircleSharp />
              </button>

              {dropdownOpen && (
                <>
                  <div
                    onClick={() => setDropdownOpen(false)}
                    className="fixed inset-0 z-10 w-full h-full"
                  ></div>
                  <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Profile
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                      Settings
                    </a>
                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white" onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}>
                      Logout
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </header>

        {/* Page content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto lg:px-6 py-8">

            <div class="py-6 flex flex-col justify-center lg:py-12 max-w-[1443px]">
              <div class="relative py-3 max-w-[1443px] sm:mx-auto">
                <div class="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl lg:p-10">
                  <div class="max-w-[1443px] mx-auto">
                    <div class="flex items-center space-x-5">
                      <div class="h-14 w-14 bg-black rounded-full flex flex-shrink-0 justify-center items-center text-white text-2xl font-mono">A</div>
                      <div class="block pl-2 font-semibold text-xl text-gray-700">
                        <h2 class="leading-relaxed">Create An Assignment</h2>
                      </div>
                    </div>
                    <div class="divide-y divide-gray-200">
                      <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                        <div class="flex flex-col">
                          <label class="leading-loose">Assignment Title</label>
                          <input type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Assignment Title" value={title}
                            onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div class="block text-xl text-gray-700">
                          <h2 class="leading-relaxed">Questions:</h2>
                        </div>
                        {questions.map((question, index) => (
                          <div key={index} className='flex lg:flex-row flex-col gap-3'>
                            <input
                              type="text"
                              placeholder="Question Text"
                              className='w-[500px] border p-2'
                              value={question.questionText}
                              onChange={(e) => {
                                const newQuestions = [...questions];
                                newQuestions[index].questionText = e.target.value;
                                setQuestions(newQuestions);
                              }}
                            />
                            {question.answerOptions.map((option, i) => (
                              <input
                                key={i}
                                type="text"
                                className='border p-2 w-[150px]'
                                placeholder={`Option ${i + 1}`}
                                value={option}
                                onChange={(e) => {
                                  const newQuestions = [...questions];
                                  newQuestions[index].answerOptions[i] = e.target.value;
                                  setQuestions(newQuestions);
                                }}
                              />
                            ))}
                            <input
                              type="text"
                              placeholder="Correct Answer"
                              className='border p-2 w-[150px]'
                              value={question.correctAnswer}
                              onChange={(e) => {
                                const newQuestions = [...questions];
                                newQuestions[index].correctAnswer = e.target.value;
                                setQuestions(newQuestions);
                              }}
                            />
                          </div>
                        ))}

                        <div class="flex items-center space-x-4">
                          <div class="flex flex-col">
                            <label class="leading-loose">Start</label>
                            <div class="relative focus-within:text-gray-600 text-gray-400">
                              <input type="date" class="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="25/02/2020" value={startDate}
                                onChange={(e) => setStartDate(e.target.value)} />
                              <div class="absolute left-3 top-2">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                              </div>
                            </div>
                          </div>
                          <div class="flex flex-col">
                            <label class="leading-loose">End</label>
                            <div class="relative focus-within:text-gray-600 text-gray-400">
                              <input type="date" class="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="26/02/2020" value={endDate}
                                onChange={(e) => setEndDate(e.target.value)} />
                              <div class="absolute left-3 top-2">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="pt-4 flex items-center space-x-4">
                        {/* <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                          <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Cancel
                        </button>
                        <button class="bg-black flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Create</button> */}
                        <button onClick={addQuestion} className='bg-black flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none'>Add Question</button>
                        <button onClick={handleCreateAssignment} className='bg-black flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none'>Create Assignment</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};