'use client'

import { useState, useEffect } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Logo } from '../../Assets'

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [hasScrolled, setHasScrolled] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const scrollThreshold = 100;

            setHasScrolled(scrollPosition > scrollThreshold);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header className={`bg-${hasScrolled ? "white" : "white"
            } fixed top-0 w-full z-[100]`} id='header'>
            <nav aria-label="Global" className="mx-auto flex max-w-[1443px] items-center justify-between py-6 px-2 md:px-5 lg:px-8">
                <div className="flex">
                    <a
                        href="/"
                        aria-label="GataProtocol"
                        class="inline-flex items-center"
                    >
                        <img src={Logo} alt="Logo" className="lg:w-40 lg:h-auto w-[100px]" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-black"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
                <div className="hidden lg:flex md:hidden items-center lg:gap-x-8">
                    <a
                        href={'/'}
                        class="hidden lg:flex items-center justify-center font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                        aria-label="Contact Us"
                    >
                        Home
                    </a>
                    <a
                        href={'/about'}
                        class="hidden lg:flex items-center justify-center font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                        aria-label="Contact Us"
                    >
                        About
                    </a>
                    <a
                        href={'/contact'}
                        class="hidden lg:flex items-center justify-center font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                        aria-label="Contact Us"
                    >
                        Contact
                    </a>
                </div>
                <div className='lg:flex hidden gap-4'>
                    <a
                        href={'/webapp/signup'}
                        class="hidden lg:flex items-center justify-center h-12 px-8 font-bold text-lg tracking-wide text-white transition duration-200 shadow-md bg-black hover:opacity-[.9] focus:shadow-outline focus:outline-none rounded-lg"
                        aria-label="Contact Us"
                    >
                        Sign Up
                    </a>
                    <a
                        href={'/webapp/signin'}
                        class="hidden lg:flex items-center justify-center h-12 px-8 font-bold text-lg tracking-wide text-white transition duration-200 shadow-md bg-black hover:opacity-[.9] focus:shadow-outline focus:outline-none rounded-lg"
                        aria-label="Contact Us"
                    >
                        Sign In
                    </a>
                </div>
            </nav>
            <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden overflow-x-hidden">
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-[100] w-full overflow-y-auto bg-white px-2 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a
                            href="/"
                            aria-label="Yiieldy"
                            title="Yiieldy"
                            class="inline-flex items-center"
                        >
                            <img src={Logo} alt="Logo" className="w-[100px]" />
                        </a>
                        <button
                            type="button"
                            onClick={() => setMobileMenuOpen(false)}
                            className="-m-2.5 rounded-md p-2.5 text-black"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="-my-6 divide-y divide-gray-500/10 px-1">
                        <div className="space-y-2 py-6">
                            <div className="space-y-2 py-6 px-2">
                                <a
                                    href={'/'}
                                    class="flex lg:hidden items-center justify-start font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                                    aria-label="Contact Us"
                                >
                                    Home
                                </a>
                                <a
                                    href={'/about'}
                                    class="flex lg:hidden items-center justify-start font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                                    aria-label="Contact Us"
                                >
                                    About
                                </a>
                                <a
                                    href={'/contact'}
                                    class="flex lg:hidden items-center justify-start font-bold text-lg tracking-wide text-black transition duration-200 focus:shadow-outline focus:outline-none rounded-lg"
                                    aria-label="Contact Us"
                                >
                                    Contact
                                </a>
                                <a
                                    href="/webapp/signup"
                                    className="flex lg:hidden items-center justify-center h-12 px-8 font-bold text-lg tracking-wide text-white transition duration-200 shadow-md bg-black hover:opacity-[.9] focus:shadow-outline focus:outline-none rounded-lg"
                                    aria-label="Contact Us"
                                >
                                    Sign Up
                                </a>
                                <a
                                    href="/webapp/signin"
                                    className="flex lg:hidden items-center justify-center h-12 px-8 font-bold text-lg tracking-wide text-white transition duration-200 shadow-md bg-black hover:opacity-[.9] focus:shadow-outline focus:outline-none rounded-lg"
                                    aria-label="Contact Us"
                                >
                                    Sign In
                                </a>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    )
}
