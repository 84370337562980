import React, { useState, useEffect, useContext } from 'react';
import { Logo } from '../../../../../Assets';
import { MdOutlineAssignmentTurnedIn, MdOutlineAssignmentInd, MdNotificationAdd } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom'
import { getAllStudentsAndAdmins } from '../../../../../apiService/apiService';
import { AuthContext } from '../../../../Context/AuthContext';

export default function Students() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [name, setName] = useState('');
    const { setUser } = useContext(AuthContext);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setUser(null);
        navigate('/webapp/schadsignin', { replace: true });
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    const fetchStudents = async () => {
        try {
            const response = await getAllStudentsAndAdmins();
            console.log(response.data);
            setStudents(response.data);
        } catch (error) {
            console.error("Failed to fetch students", error);
        }
    };

    return (
        <div className="flex h-screen bg-white">
            {/* Sidebar overlay */}
            {sidebarOpen && (
                <div
                    onClick={() => setSidebarOpen(false)}
                    className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
                ></div>
            )}

            {/* Sidebar */}
            <div
                className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition-transform transform bg-white ${sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
                    } lg:translate-x-0 lg:static lg:inset-0`}
            >
                <div className="flex items-center px-4 mt-8">
                    <div className="flex items-center">
                        <img src={Logo} alt='Logo' className='w-40' />
                    </div>
                </div>

                <nav className="mt-10">
                    <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/teacher/assignmentset">
                        <div className='text-[20px] font-bold'>
                            <MdOutlineAssignmentTurnedIn />
                        </div>
                        <span className="mx-3">AssignmentsSet</span>
                    </a>

                    <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="/webapp/teacher/assign">
                        <div className='text-[20px] font-bold'>
                            <MdOutlineAssignmentInd />
                        </div>
                        <span className="mx-3">Assign Student Admin</span>
                    </a>

                    <a className="flex items-center px-6 py-2 mt-4 text-white bg-black" href="/webapp/teacher/students">
                        <div className='text-[20px] font-bold'>
                            <PiStudentBold />
                        </div>
                        <span className="mx-3">Students</span>
                    </a>

                    <a className="flex items-center px-6 py-2 mt-4 text-black hover:border hover:border-black hover:text-black" href="#">
                        <div className='text-[20px] font-bold'>
                            <FaChalkboardTeacher />
                        </div>
                        <span className="mx-3">Courses</span>
                    </a>
                </nav>
            </div>

            {/* Main content */}
            <div className="flex flex-col flex-1 overflow-hidden">
                <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-black">
                    <div className="flex items-center">
                        <button
                            onClick={() => setSidebarOpen(true)}
                            className="text-gray-500 focus:outline-none lg:hidden"
                        >
                            <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>

                        <div className="relative mx-4 lg:mx-0">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></path>
                                </svg>
                            </span>

                            <input
                                className="w-32 pl-10 pr-4 rounded-md form-input sm:w-64 focus:border-black"
                                type="text"
                                placeholder="Search"
                            />
                        </div>
                    </div>

                    <div className="flex items-center">
                        {/* Notification */}
                        <div className="relative">
                            <button onClick={() => setNotificationOpen(!notificationOpen)} className="flex text-[32px] mx-4 text-gray-600 focus:outline-none">
                                <MdNotificationAdd />
                            </button>

                            {notificationOpen && (
                                <div className="absolute right-0 w-64 mt-2 py-2 bg-white border rounded-lg shadow-xl">
                                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                                        Notification 1
                                    </a>
                                    <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                                        Notification 2
                                    </a>
                                </div>
                            )}
                        </div>

                        {/* Profile dropdown */}
                        <div className="relative">
                            <button
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className="relative block text-[32px] overflow-hidden rounded-full shadow focus:outline-none"
                            >
                                <IoPersonCircleSharp />
                            </button>

                            {dropdownOpen && (
                                <>
                                    <div
                                        onClick={() => setDropdownOpen(false)}
                                        className="fixed inset-0 z-10 w-full h-full"
                                    ></div>
                                    <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                                        <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                                            Profile
                                        </a>
                                        <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white">
                                            Products
                                        </a>
                                        <a href="#" className="block px-4 py-2 text-sm text-black hover:bg-black hover:text-white" onClick={(e) => {
                                            e.preventDefault();
                                            handleLogout();
                                        }}>
                                            Logout
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </header>

                {/* Page content */}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
                    <div className="container mx-auto px-6 py-8">
                        {/* List of students */}
                        <div class="flex flex-col justify-start lg:py-12 max-w-[1443px]">
                            <div class="flex flex-wrap mb-5">
                                <div class="w-[500px] mb-6">
                                    <div class="relative flex-[1_auto] flex flex-col break-words bg-clip-border rounded-[.95rem] bg-white">
                                        <div class="relative flex flex-col break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                                            {/* table header */}
                                            <div class="px-9 pt-5 flex justify-between items-stretch flex-wrap min-h-[70px] pb-0 bg-transparent">
                                                <h3 class="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                                                    <span class="mr-3 font-semibold text-dark">List of students</span>
                                                </h3>
                                            </div>


                                            <div class="flex-auto block py-8 pt-6 px-9">
                                                <div class="overflow-x-auto">
                                                    <table class="w-[400px] my-0 align-middle text-dark border-neutral-200">
                                                        <thead class="align-bottom">
                                                            <tr class="font-semibold text-[0.95rem] text-secondary-dark">
                                                                <th className="py-2 text-start">Student Name</th>
                                                                <th className="py-2 text-start">Students ID</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {students.length > 0 ? (
                                                                students.map((students) => (
                                                                    <tr key={students.userId} className="border-b border-dashed last:border-b-0">
                                                                        <td className="py-3 pl-0">
                                                                            <div className="flex items-start">
                                                                                <div className="flex flex-col justify-start">
                                                                                    <p className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary">
                                                                                        {students.name}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="py-3 pr-0 text-start">
                                                                            <span className="font-semibold text-light-inverse text-md/normal">
                                                                                {students.userId}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="5" className="p-3 text-center text-gray-500">
                                                                        No students available
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};